<template>
  <section class="intro-about">
    <!-- About page header -->
    <div class="container" data-aos="fade-up" data-aos-duration="1500">
      <div class="row">
        <div class="col col1"></div>
        <div class="col-lg-6  col-10">
          <h1>
            Het verhaal achter <br />
            <span class="logo-font"> Liefs, Lune</span>
          </h1>
        </div>
        <div class="col d-flex justify-content-start">
          <img src="@/assets/img/header-heart.png" />
        </div>
      </div>
    </div>
    <div class="waves">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 1919 147.568"
      >
        <path
          id="Intersection_16"
          data-name="Intersection 16"
          d="M59.948-3953.432v-97.177c26.559-12.49,60.384-23.023,98.93-23.023,94.683,0,143.43,63.553,217.853,63.553s148.343-68.88,206.915-63.553,99.738,63.553,161.8,63.553,71.029-48.5,141.825-44.332,114.686,68.7,190.618,65.78S1182.14-4091.177,1244-4100.276a65.514,65.514,0,0,1,9.422-.724h.155c52.531.059,56.357,71.462,121.357,75.972,68.737,4.769,60.769-75.248,144.607-75.248s86.951,90.2,172.517,90.2,93.424-90.2,184.274-90.2c49.578,0,82.725,22.633,102.616,44.154v102.69Z"
          transform="translate(-59.948 4101)"
          fill="#fff"
        />
      </svg>
    </div>
  </section>
  <!-- Sectie 1 -->
  <section class="about-section1">
    <div class="container">
      <div class="row justify-content-center" >
        <div class="col-10 col-sm-9">
          <p>
            Toen we nog zwanger waren van Lune kregen we het slechte nieuws dat 
            de NIPT (Niet Invasieve Prenatale Test) niet goed was. We hebben 
            uiteindelijk de moeilijkste beslissing van ons leven moeten maken om 
            Lune te laten gaan. In de periode naar de bevalling hebben we veel 
            spulletjes voor haar kunnen maken, waaronder een wikkelsetje (wikkeldoekje + bijpassend doekje). 
            Omdat de verloskundige niet met 100% zekerheid kon zeggen dat het een meisje was, 
            hebben we voor de zekerheid nog een extra setje gemaakt die ook eventueel voor een jongetje gebruikt kon worden.<br />
            Bij de bevalling bleek het toch wel een meisje te zijn en was het andere setje overbodig. 
            Omdat wij deze niet meer hoopten te moeten gebruiken, hebben we deze aan de verpleegkundige gegeven. 
            Dan konden ze deze aan andere ouders, die hem wel konden gebruiken, geven. De verpleegkundige vond deze enorm mooi en lief dat deze naar iemand anders mocht gaan.<br />
            Tijdens het afsluitende gesprek met de verliescounselor hebben we gevraagd of ze misschien interesse hadden in meer van deze doekjes. Deze waren meer dan welkom!
            <br /><br />
            <strong>Zo is het idee ontstaan &hearts;</strong>
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- Sectie 2 -->
  <section class="about-section2">
    <div class="container">
      <div class="row" data-aos="fade-up" data-aos-duration="1500">
        <div class="col-sm col-10">
          <h2>Waarom?</h2>
          <p>
            Als een kindje tijdens de zwangerschap overlijdt, of de zwangerschap wordt afgebroken, 
            is een wikkeldoekje vaak niet het eerste waar aan wordt gedacht. Toch is het heel waardevol 
            als het kindje toch in een mooi doekje kan liggen en vastgehouden kan worden. 
            Dat is vaak prettiger dan dat het kindje in een willekeurige doek wordt gewikkeld. 
            Het bijpassende doekje dat erbij zit is voor de ouders zelf. Dan kan het kindje in het 
            wikkeldoekje blijven als hij/zij naar haar laatste bestemming wordt gebracht. De ouders kunnen 
            dit doekje aanraken, vasthouden, knuffelen of gewoon bewaren. Dit doekje is van dezelfde stoffen 
            gemaakt als het wikkeldoekje voor het kindje. Daardoor een blijvende herinnering aan het kindje.
          </p>
        </div>
        <div class="col">
          <h2>Hoe kom ik aan zo'n setje?</h2>
          <p>
            De setjes worden bij voorkeur aan ziekenhuizen geschonken. 
            Deze ziekenhuizen hebben meerdere setjes waaruit de ouders kosteloos 
            een mooi exemplaar kunnen kiezen. Stel jullie hebben net het 
            slechte nieuws gehoord dat het niet goed gaat met jullie kindje. 
            Jullie willen graag een wikkelsetje, maar zullen in een ziekenhuis 
            bevallen van jullie sterrenkindje die nog niet is aangesloten. 
            Dat is geen probleem! Vul het contactformulier in en we reageren zo snel mogelijk.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- Sectie 3 -->
  <section class="about-section3">
    <div class="container">
      <div class="row" data-aos="fade-up" data-aos-duration="1500">
        <div class="col-md col-11">
          <h2>Hoe wordt het gefinancierd?</h2>
          <ul>
            <li>
              <div>
                <img src="@/assets/img/vink-blue.png" class="img-fluid check" />
                Door gelddonaties om materialen aan te kunnen schaffen
              </div>
            </li>
            <li>
              <div>
                <img
                  src="@/assets/img/vink-blue.png"
                  class="img-fluid check"
                />Door stofdonaties om de setjes te maken
              </div>
            </li>
          </ul>
          <p>
            Door alle donaties is het mogelijk om de setjes kosteloos aan te bieden. 
            Dit willen we graag zo lang mogelijk kosteloos blijven doen. 
            Alle donaties zijn daarom meer dan welkom! Meer informatie vind je hierover bij doneren.
          </p>
          <button @click="$router.push('Doneren')" class="d-inline">Doneren</button>
        </div>
        <div class="col-md col-11">
          <img :src="donateIllustration" class="image-fluid donate-image" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      donateIllustration: require("../assets/img/help-illustratie@2x.png"),
    };
  },
};
</script>
